import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import {
    Hidden,
    Menu,
    MenuItem,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { set } from "date-fns";


const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        background: "#eee",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
        },
    },

    newMessageBox: {
        background: "#eee",
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },

    messageInputWrapper: {
        padding: 6,
        marginRight: 7,
        background: "#fff",
        display: "flex",
        borderRadius: 20,
        flex: 1,
        position: "relative",
    },

    messageInput: {
        paddingLeft: 10,
        flex: 1,
        border: "none",
    },

    sendMessageIcons: {
        color: "grey",
    },

    uploadInput: {
        display: "none",
    },

    viewMediaInputWrapper: {
        display: "flex",
        padding: "10px 13px",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#eee",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    emojiBox: {
        position: "absolute",
        bottom: 63,
        width: 40,
        borderTop: "1px solid #e8e8e8",
    },

    circleLoading: {
        color: green[500],
        opacity: "70%",
        position: "absolute",
        top: "20%",
        left: "50%",
        marginLeft: -12,
    },

    audioLoading: {
        color: green[500],
        opacity: "70%",
    },

    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },

    replyginMsgWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 8,
        paddingLeft: 73,
        paddingRight: 7,
    },

    replyginMsgContainer: {
        flex: 1,
        marginRight: 5,
        overflowY: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },

    replyginMsgBody: {
        padding: 10,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },

    replyginContactMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#35cd96",
    },

    replyginSelfMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },

    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },
    messageQuickAnswersWrapper: {
        margin: 0,
        position: "absolute",
        bottom: "50px",
        background: "#ffffff",
        padding: "2px",
        border: "1px solid #CCC",
        left: 0,
        width: "100%",
        "& li": {
            listStyle: "none",
            "& a": {
                display: "block",
                padding: "8px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxHeight: "32px",
                "&:hover": {
                    background: "#F1F1F1",
                    cursor: "pointer",
                },
            },
        },
    },
}));

const ChatMessageInput = ({ chat }) => {
    const classes = useStyles();
    const inputRef = useRef();
    const [inputMessage, setInputMessage] = useState("");
    const [showEmoji, setShowEmoji] = useState(false);
    const [medias, setMedias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaName, setMediaName] = useState("");

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
          }
          return () => {
            setInputMessage("");
            setMedias([]);
            setShowEmoji(false);
            setMediaUrl("");
            setMediaName("");
          };
    }, [chat]);

    const handleChangeInput = (e) => {
        setInputMessage(e.target.value);
    };

    const handleAddEmoji = (e) => {
        let emoji = e.native;
        setInputMessage((prevState) => prevState + emoji);
    };

    const handleChangeMedias = (e) => {
        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    const handleInputPaste = (e) => { //Objetivo: Função que lida com a colagem de arquivos de mídia
        if (e.clipboardData.files[0]) {
            setMedias([e.clipboardData.files[0]]);
        }
    };

    const handleUploadMedia = async (e) => { //Objetivo: Função que envia arquivos de mídia
        setLoading(true);
        e.preventDefault();

        const formData = new FormData();
        medias.forEach((media) => { //objetivo: Envia as mídias
            formData.append("medias", media);            
        });
        try {
            await api.post(`/chats/${chat.id}/messages`, formData);
            
        } catch (err) {
            toastError(err);
        }

        setLoading(false);
        setMedias([]);        
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() === "" && !medias) return;
        setLoading(true);

        const message = {
            message: inputMessage,
        };

        try {
            await api.post(`/chats/${chat.id}/messages`, message);
            //console.log("Mensagem: ", message);

        } catch (err) {
            toastError(err);
        }

        setInputMessage("");
        setLoading(false);
        setShowEmoji(false);
    };

    const handleOpenMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event) => {
        setAnchorEl(null);
    };

    if (medias.length > 0) {
        return (
            <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
                <IconButton
                    aria-label="cancel-upload"
                    component="span"
                    onClick={(e) => setMedias([])}
                >
                    <CancelIcon className={classes.sendMessageIcons} />
                </IconButton>

                {loading ? (
                    <div>
                        <CircularProgress className={classes.circleLoading} />
                    </div>
                ) : (
                    <span>
                        {medias[0]?.name}
                        {//<img src={media.preview} alt=""></img>
                        }
                    </span>
                )}
                <IconButton
                    aria-label="send-upload"
                    component="span"
                    onClick={handleUploadMedia}
                    disabled={loading}
                >
                    <SendIcon className={classes.sendMessageIcons} />
                </IconButton>
            </Paper>
        );
    } else {
        return (
            <Paper square elevation={0} className={classes.mainWrapper}>
                <div className={classes.newMessageBox}>
                    <Hidden only={["sm", "xs"]}>
                        <IconButton
                            aria-label="emojiPicker"
                            component="span"
                            disabled={loading}
                            onClick={(e) => setShowEmoji((prevState) => !prevState)}
                        >
                            <MoodIcon className={classes.sendMessageIcons} />
                        </IconButton>
                        {showEmoji ? (
                            <div className={classes.emojiBox}>
                                <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                                    <Picker
                                        perLine={16}
                                        showPreview={false}
                                        showSkinTones={false}
                                        onSelect={handleAddEmoji}
                                    />
                                </ClickAwayListener>
                            </div>
                        ) : null}

                        <input
                            multiple
                            type="file"
                            id="upload-button"
                            disabled={loading}
                            className={classes.uploadInput}
                            onChange={handleChangeMedias}
                        />
                        <label htmlFor="upload-button">
                            <IconButton
                                aria-label="upload"
                                component="span"
                                disabled={loading}
                            >
                                <AttachFileIcon className={classes.sendMessageIcons} />
                            </IconButton>
                        </label>
                    </Hidden>
                    <Hidden only={["md", "lg", "xl"]}>
                        <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleOpenMenuClick}
                        >
                            <MoreVert></MoreVert>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            keepMounted
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuItemClick}
                        >
                            <MenuItem onClick={handleMenuItemClick}>
                                <IconButton
                                    aria-label="emojiPicker"
                                    component="span"
                                    disabled={loading}
                                    onClick={(e) => setShowEmoji((prevState) => !prevState)}
                                >
                                    <MoodIcon className={classes.sendMessageIcons} />
                                </IconButton>
                            </MenuItem>
                            <MenuItem onClick={handleMenuItemClick}>
                                <input
                                    multiple
                                    type="file"
                                    id="upload-button"
                                    disabled={loading}
                                    className={classes.uploadInput}
                                    onChange={handleChangeMedias}
                                />
                                <label htmlFor="upload-button">
                                    <IconButton
                                        aria-label="upload"
                                        component="span"
                                        disabled={loading}
                                    >
                                        <AttachFileIcon className={classes.sendMessageIcons} />
                                    </IconButton>
                                </label>
                            </MenuItem>
                        </Menu>
                    </Hidden>
                    <div className={classes.messageInputWrapper}>
                        <InputBase
                            inputRef={(input) => {
                                input && input.focus();
                                input && (inputRef.current = input);
                            }}
                            className={classes.messageInput}
                            placeholder={"Digite uma mensagem..."}
                            multiline
                            maxRows={5}
                            value={inputMessage}
                            onChange={handleChangeInput}
                            disabled={loading}
                            onPaste={(e) => {
                                handleInputPaste(e);
                            }}
                            onKeyPress={(e) => {
                                if (loading || e.shiftKey) return;
                                else if (e.key === "Enter") {
                                    handleSendMessage();
                                }
                            }}
                        />
                    </div>
                    <IconButton
                        aria-label="sendMessage"
                        component="span"
                        onClick={handleSendMessage}
                        disabled={loading}
                    >
                        <SendIcon className={classes.sendMessageIcons} />
                    </IconButton>
                </div>
            </Paper>
        );
    }

};

export default ChatMessageInput;
