import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import { getClientName } from "../config";
import {
    makeStyles,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    MenuItem,
    IconButton,
    Menu,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import { getBackendUrl } from "../config";


const drawerWidth = 230;

const logo = require("../assets/logo4.png");
const logo2 = require("../assets/logo.png");

const ClientNameDash = getClientName();
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        display: "flex",
        height: "100vh",
        [theme.breakpoints.down("sm")]: {
            height: "calc(100vh - 56px)",
        },
        fontFamily: "Nunito",
    },

    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: "white",
        //backgroundColor: '#151d86'//'#660000',
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "48px",
        /*
                padding: "0 8px",
                minHeight: "48px",
        */
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //position: "fixed",        
        //zIndex: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        width: "100%",
        paddingLeft: 24,
        /*transition: theme.transitions.create(["width", "margin"], { 
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),*/
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: "100%",
        /*transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),*/
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontFamily: "Nunito",
        fontSize: "1.2rem",
        fontWeight: 800,
        textTransform: "uppercase",
        letterSpacing: "0.05rem",
    },
    drawerPaper: {
        backgroundColor: "#ededed",
        //backgroundColor: "#151d86",
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        ...theme.scrollbarStyles,
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: {
        minHeight: "48px",
        //position: "fixed",
        width: "100%",
    },
    content: {
        flex: 1,
        overflow: "auto",
        ...theme.scrollbarStyles,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        //overflow: "auto",
        flexDirection: "column",

    },
    list: {
        //marginTop: "80px",
        overflowY: "auto",
        overflowX: "hidden",
        height: "calc(100% - 80px)",
        padding: theme.spacing(1),
        ...theme.scrollbarStyles,
    },
    hideOnSm: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
}));

const LoggedInLayout = ({ children }) => {
    const classes = useStyles();
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const { handleLogout, loading } = useContext(AuthContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerVariant, setDrawerVariant] = useState("permanent");

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (document.body.offsetWidth > 600) {
            setDrawerOpen(true);

        }
    }, []);

    useEffect(() => {

        if (document.body.offsetWidth < 600) {
            setDrawerVariant("temporary");
        } else {
            setDrawerVariant("permanent");
        }
    }, [drawerOpen]);

    //console.log("drawerOpen", drawerOpen);
    //console.log("drawerVariant", drawerVariant);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleOpenUserModal = () => {
        setUserModalOpen(true);
        handleCloseMenu();
    };

    const handleClickLogout = () => {
        handleCloseMenu();
        handleLogout();
    };

    const drawerClose = () => {
        if (document.body.offsetWidth < 600) {
            setDrawerOpen(false);
        }
    };


    if (loading) {

        return <BackdropLoading />;
    }

    let url = getBackendUrl();

    return (
        <div className={classes.root}>
            <Drawer
                variant={drawerVariant}
                className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !drawerOpen && classes.drawerPaperClose
                    ),
                }}
                open={drawerOpen}
            >

                <div className={classes.toolbarIcon}>
                    {drawerOpen ? (<img src={logo} style={{ height: "65px", padding: "10px" }} alt="Logo" />)
                        : (<img src={logo2} style={{ height: "65px", padding: "10px" }} alt="Logo" />)
                    }
                </div>
                <Divider variant="middle" />
                <div className={classes.list}>
                    <List >
                        <MainListItems drawerClose={drawerOpen} />
                    </List>
                </div>
                <Divider variant="middle" />
            </Drawer>
            <UserModal
                open={userModalOpen}
                onClose={() => setUserModalOpen(false)}
                userId={user?.id}
            />
            <AppBar
                position="absolute"
                className={classes.appBar}
                //{clsx(classes.appBar, drawerOpen && classes.appBarShift)}
                color={process.env.NODE_ENV === "development" ? "inherit" : "primary"}
            >
                <Toolbar variant="dense" className={classes.toolbar}>

                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={classes.menuButton}
                    //{clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {ClientNameDash}

                    </Typography>
                    {user.id && <NotificationsPopOver />}

                    {user.id &&
                        <Typography variant="body2" color="inherit" noWrap className={classes.hideOnSm}>
                            Olá, {user.name}
                        </Typography>
                    }

                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            style={{ padding: "9px 12px"  }}
                        > 
                          {user.mediaUrl ? 
                          ( <img src={`${url}/public/${user.mediaUrl}`} 
                            style={{ width: "24px", height: "24px", borderRadius: "100%" }} 
                            alt="Foto" />) : 
                          (<AccountCircle style={{ width: "24px", height: "24px" }} />)}                            
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={menuOpen}
                            onClose={handleCloseMenu}
                        >

                            {document.body.offsetWidth < 600 && (
                                <MenuItem disabled style={{ pointerEvents: 'none' }}>
                                    Olá, {user.name}
                                </MenuItem>
                            )}
                            <MenuItem onClick={handleOpenUserModal}>
                                {i18n.t("mainDrawer.appBar.user.profile")}
                            </MenuItem>
                            <MenuItem onClick={handleClickLogout}>
                                {i18n.t("mainDrawer.appBar.user.logout")}
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                {children ? children : null}
            </main>
        </div>
    );
};

export default LoggedInLayout;
