import React, { useContext, useEffect, useState } from "react";
import { format, isSameDay, parseISO, set } from "date-fns";
import {
  Badge,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import ConfirmationModal from "../../components/ConfirmationModal";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    height: "calc(100% - 58px)",
    overflow: "hidden",
    borderRadius: 0,
    //backgroundColor: "inherit",
  },
  chatList: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  listItem: {
    cursor: "pointer",
  },
}));

export default function ChatList({
  chats,
  handleSelectChat,
  handleDeleteChat,
  handleEditChat,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [confirmationModal, setConfirmModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState({});

  useEffect(() => {
    if (pageInfo && pageInfo.page === 1) {
      setSelectedChat({});
    }
  }, [pageInfo]);

  const goToMessages = async (chat) => {
    if (unreadMessages(chat) > 0) {
      const currentUser = chat.users.find((u) => u.userId === user.id);
      const ownerUser = chat.users.find((u) => u.ownerId === user.id);

      if (user.profile === "admin" && !currentUser && !ownerUser) { //NÃO É OU, TEM QUE SER E!!!! PQ ASSIM ELE NÃO ATUALIZAO ESTADO!
        //console.log("Sou admin e não estou na lista de usuários do chat");
        handleSelectChat(chat);
        history.push("/chats");

      } else {

        try {
          await api.post(`/chats/${chat.id}/read`, { userId: user.id });
          // Atualizar o estado apenas após a solicitação ser concluída com sucesso 
          //console.log("Sou usuário comum e estou na lista de usuários do chat");       
          handleSelectChat(chat);
          history.push("/chats");
        } catch (err) {
          toastError(err);
        }
      }
    } else {
      // Se não houver mensagens não lidas, atualize o estado imediatamente
      handleSelectChat(chat);
      history.push("/chats");
    }
  };

  const handleDelete = () => {
    handleDeleteChat(selectedChat);
    setConfirmModalOpen(false);
  };

  const unreadMessages = (chat) => {
    if (chat && chat.users) {      
      const currentUser = chat.users.find((u) => u.userId === user.id);
      const ownerUser = chat.users.find((u) => u.ownerId === user.id);      
      if (currentUser) {
        return currentUser.unreads > 0;
      } else if (ownerUser) {
        return ownerUser.unreads > 0;
      }
    }

    // Se o usuário for admin, retornar 0 para unread messages, pois ele pode ver todos os chats
    if (user.profile === 'admin') {      
      for (let i = 0; i < chat.users.length; i++) {
        if (chat.users[i].unreads > 0) {
          return 1;
        }
        return 0;
      }
    }

    return 0;

  };

  const getPrimaryText = (chat) => {
    const mainText = chat.title;
    const unreads = unreadMessages(chat);
    return (
      <>
        {mainText}
        {unreads > 0 && (
          <Badge
            //size="small"
            style={{ marginLeft: 15 }}
            badgeContent=" "
            variant="dot"
            color="secondary"
          />
        )}
      </>
    );
  };

  const getSecondaryText = (chat) => {

    return chat.lastMessage !== "" && isSameDay(parseISO(chat.updatedAt), new Date()) ? (
      <>{format(parseISO(chat.updatedAt), "HH:mm")}</>
    ) : (
      <>{format(parseISO(chat.updatedAt), "dd/MM/yyyy")}</>
    )
  };

  const getItemStyle = (chat) => {
    // Lista de cores
    const colors = ["#002d6e", "#4caf50", "#ff9800", "#9c27b0", "#e91e63", "#FFDE59"];

    // Lógica para selecionar a cor com base no ID do chat
    const colorIndex = chat.id % colors.length; // Usa o resto da divisão pelo número de cores disponíveis

    return {
      borderLeft: chat.id === selectedChat.id ? `6px solid ${colors[colorIndex]}` : "6px solid #515151",
      backgroundColor: chat.id === selectedChat.id ? "#B3B5B1" : "inherit",
    };
  };


  return (
    <>
      <ConfirmationModal
        title={"Excluir Conversa"}
        open={confirmationModal}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleDelete}
      >
        Esta ação não pode ser revertida, confirmar?
      </ConfirmationModal>
      <div className={classes.mainContainer}>
        <div className={classes.chatList}>
          <List>
            {Array.isArray(chats) &&
              chats.length > 0 &&
              chats.map((chat, key) => (
                <ListItem
                  onClick={() => {
                    goToMessages(chat);
                    setSelectedChat(chat);
                  }}
                  key={key}
                  className={classes.listItem}
                  style={getItemStyle(chat)}
                  button
                >
                  <ListItemText
                    primary={getPrimaryText(chat)}
                    secondary={getSecondaryText(chat)}
                  />
                  <ListItemSecondaryAction>
                    {(chat.ownerId === user.id || user.profile === "admin") && (
                      <IconButton
                        onClick={() => {
                          goToMessages(chat).then(() => {
                            handleEditChat(chat);
                          });
                        }}
                        edge="end"
                        aria-label="edit"
                        size="small"
                        style={{ marginRight: 5 }}
                      >
                        <EditIcon />
                      </IconButton>)}
                    {user.profile === "admin" && (
                      <IconButton
                        onClick={() => {
                          setSelectedChat(chat);
                          setConfirmModalOpen(true);
                        }}
                        edge="end"
                        aria-label="delete"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>)}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    </>
  );
}
