//Objetivo: listar os tickets que estão em aberto e que finalizados em um certo intervalo de tempo.
import React, { useEffect, useState } from "react";
import useUsers from "../../hooks/useUsers";
import { format } from "date-fns";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Grid } from "@material-ui/core";
import Cards from "./Cards";


export const CardsUsers = ({ initialDate, finalDate }) => {
    const [ticketsData, setTicketsData] = useState([]);
    const { users } = useUsers();

    //console.log("Users: ", users);

    useEffect(() => {
        const handleGetTicketsInformation = async () => {
            try {
                const { data } = await api.get(`dashboard/ticketsCounts?initialDate=${format(initialDate, 'yyyy-MM-dd')}&finalDate=${format(finalDate, 'yyyy-MM-dd')}`);
                setTicketsData(data);
            } catch (error) {
                toast.error('Erro ao buscar informações dos tickets');
            }
        }
        handleGetTicketsInformation();

    }, [initialDate, finalDate]);
    

    return (
        <div>
            <Grid container spacing={3} alignContent="center" justifyContent="space-between">
                {users.map((user) => {

                    const userTickets = ticketsData.data.find(ticket => ticket.userId === user.id) || {};

                    //console.log("UserTickets: ", userTickets);
                    //console.log("ticketsData: ", ticketsData.data);                    

                    return (
                        <Grid item xs={12} sm={3} key={user.id}>
                            <Cards
                                ticketOpen={userTickets?.openTickets || 0}
                                ticketClosed={userTickets?.closedTickets || 0}
                                user={user}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default CardsUsers;



