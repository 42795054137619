import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {MenuProps} from './MenuProps';
import brLocale from 'date-fns/locale/pt-BR';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {Button, FormControl, InputLabel, Select, Stack, TextField} from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import {format} from 'date-fns';
import {toast} from 'react-toastify';
import './button.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: true,
            text: 'Minutos',
            position: 'left',
        },
        datalabels: {
            display: true,
            anchor: 'start',
            offset: -30,
            align: "start",
            color: "#224abe",
            textStrokeColor: "#000",
            textStrokeWidth: 1,
            font: {
                size: 16,
                weight: "bold"

            },
        }
    },
};

export const ChartMediumTimeAttendance = ({initialDate, finalDate}) => {

    //const [initialDate, setInitialDate] = useState(new Date());
    //const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({
        data: [],
        count: 0,
    });

    /*const convertMinutes = (minutes) => {
    const hours = Math.floor((minutes) / 60);
     const mins = Math.floor(minutes % 60);
      if(minutes > 59){
       return hours
      }
      else{
        return mins
      }
       return mins
    }*/

    const dataCharts = {

        labels: ticketsData?.data.map((item) => item.nome),
        datasets: [
            {
                label: `Tempo médio`,
                data: ticketsData?.data.map((item) => Math.round((item.tempomedio/item.total))),
                borderRadius: 10,
                backgroundColor: [
                    'rgba(34, 74, 190, 0.4)',                                        
                  ],
                  borderColor: [
                    'rgb(34, 74, 190)',                                        
                  ],
                  borderWidth: 2
            }
        ]
    };    

    useEffect(() => {
        handleGetTicketsInformation();
    }, [ initialDate, finalDate ]);

    const handleGetTicketsInformation = async () => {
        try {

            const {data} = await api.get(`/dashboard/mediumAttendance?initialDate=${format(initialDate, 'yyyy-MM-dd')}&finalDate=${format(finalDate, 'yyyy-MM-dd')}`);
            setTicketsData(data);
            //console.log(data);
            //console.log(dataCharts.datasets[0].data);
        } catch (error) {
            toast.error('Erro ao buscar informações dos tickets');
        }
    }

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Tempo médio de atendimento por usuário em minutos
            </Typography>
             {/*
            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{my: 2,}}>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={initialDate}
                        onChange={(newValue) => {
                            setInitialDate(newValue)
                        }}
                        label="Data inicial"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{width: '20ch'}}/>}

                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={(newValue) => {
                            setFinalDate(newValue)
                        }}
                        label="Data final"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{width: '20ch'}}/>}
                    />
                </LocalizationProvider>

                <Button className="buttonHover" onClick={handleGetTicketsInformation}
                        variant='contained'>Filtrar</Button>

            </Stack>
            */}
            <Bar options={options} data={dataCharts} style={{maxWidth: '100%', maxHeight: '280px',}}/>
        </>
    );
}