//Objetivo: listar os tickets que estão em aberto e que finalizados em um certo intervalo de tempo.
import React, { useEffect, useState } from "react";
import { getBackendUrl } from "../../config";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ReactComponent as PictureUser } from 'bootstrap-icons/icons/person-circle.svg';

const useStyles = makeStyles((theme) => ({
    cardUserPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 150,
        borderBottom: "8px solid",
        borderBottomColor: "#7436a3",
    },
    image: {
        width: 45,
        height: 45,
        borderRadius: 100,
    },
}));

export const Cards = ({ ticketOpen, ticketClosed, user }) => {
    const classes = useStyles();

    //const [ticketsOpen, setTicketsOpen] = useState(0);
    //const [ticketsClosed, setTicketsClosed] = useState(0);

    const url = getBackendUrl();

    /*useEffect(() => {

        if (Array.isArray(ticketData) && ticketData.length > 0) {
            console.log("TicketData: ", ticketData);
            const tickets = ticketData.find(ticket => ticket.userId === user.id) || {};
    
            if (tickets) {
                setTicketsOpen(tickets.openTickets || 0);
                setTicketsClosed(tickets.closedTickets || 0);
                console.log("Tickets Open: ", ticketsOpen);
                console.log("Tickets Closed: ", ticketsClosed);
            }
        } else {
            // Se ticketData não for um array ou for um array vazio, resetar os estados
            setTicketsOpen(0);
            setTicketsClosed(0);
        }

    }, [ticketData, user.id]);*/

    /*const ticketsOpen = ticketData?.openTickets || 0;
    const ticketsClosed = ticketData?.closedTickets || 0;*/

    //console.log("Tickets Open: ", ticketOpen);
    //console.log("Tickets Closed: ", ticketClosed);
    

    return (
        <div>
            <Paper className={classes.cardUserPaper} elevation={3} style={{ overflow: "hidden" }}>
                <Grid container alignItems="center" xs={12}>

                    <Grid container item direction="row" xs={12} style={{ verticalAlign: "middle" }} >
                        <Grid item xs={3} >
                            {user.mediaUrl && user.mediaUrl !== "" ? (
                                <img src={`${url}/public/${user.mediaUrl}`} alt={user.name} className={classes.image} />) : (
                                <PictureUser className={classes.image} style={{ color: "#224abe" }} />
                            )}
                        </Grid>
                        <Grid item xs={9} >
                            <Typography variant="h6" style={{ fontWeight: 700, color: "black", marginTop: "5px" }}>
                                {user.name}
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid container item direction="row" xs={12} alignContent="center" justifyContent="center" >
                        <Grid item xs={9}>
                            <Typography component="body1" color="black" paragraph>
                                Tickets Atendendo
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="body1" color="black" paragraph>
                                {ticketOpen}
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid container item direction="row" xs={12} alignContent="center" justifyContent="center">
                        <Grid item xs={9}>
                            <Typography component="body1" color="black" paragraph>
                                Tickets Resolvidos
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="body1" color="black" paragraph>
                                {ticketClosed}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default Cards;



